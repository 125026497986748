:root{
    --dark-pink:#D83F87;
    /* Three primary*/
    --dark-purple:#2A1B3D;
    --purple: #B93DAF;
    --red: #D43725;
    /**/
    --light-purple: #E98074;
    --cloudy-green: #A4B3B6;
    --primary-color:#1A1110;
    --secondary-color:#EDF5E1;
    --third-color:#5CDB95;
}

* {
    margin: 0;
    padding: 0;
}

#particles {
    z-index: -1;
    height:100%;
    width:100vw;
    position: absolute;
}

.rank-container{
    display: flex;
    width: 100vw;
    height: fit-content;
    flex-direction: column;
    
}

.header{
    display: flex;
    flex-direction: row;
    width:100%;
    margin-bottom: 1rem;
}

.header-title{
    width:100%;
    display:flex;
    align-items:center;
    justify-content: center;
    color:#C0C0C0;
    font-size:3rem ;
}

.data{
    display: flex;
    flex-direction: column;
    width:100%;
    height:80%;
}
.player-ranked-container{
    display: flex;
    flex-direction: column;
    width:100%;
    height:100%;
    color:#C0C0C0;

}
.player-ranked-container::-webkit-scrollbar {
    display: none;
}
.player-stats{
    display: flex;
    flex-direction:row ;
    
    width:100%;
    justify-content: space-between;
    
}

.stat{
    padding:0.5rem;
    max-width: fit-content;
    max-height: fit-content;
}

.name-player{
    max-width: fit-content;
}

.number{
    height:5px;
    width: fit-content;
}