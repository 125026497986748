

.chart-container{
    max-height: fit-content;
    width:100vw;
    display: flex;
    flex-direction: column;
    background-color: aliceblue;
    align-items: center;
    justify-content: center;
    overflow-y: scroll;
    overflow-x: hidden;
}

.header-chart{
    width: 100%;
    height: 60px;
    overflow-x: hidden;
}

.data-container-chart{
    width:100%;
    max-height: fit-content;
    overflow-x: hidden;
}


.radar-container{
    width:700px;
    padding:20px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x: hidden;
}

.name{
    margin-left:290px;
    color:white;

}

.link-logo{
    text-decoration: none;
    height: 60px;
}

.logo-fyp{
    height:200px;
    width:230px;
    font-size: 2.5rem;
    text-decoration: none;
    font-family: "Bowlby One SC", sans-serif;
}

.logo{
    height:100%;
    width:100%;
}

.chart{
    display: flex;
    align-items: center;
    justify-content: space-around;
    width:100%;
    height: auto;
}

.btn-chart{
    height: 100%;
    width:auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 5rem;
    color:grey;
    font-weight: 800;
}

.polar-chart{
    width:500px;
    height: auto;
}

.index-chart{
    font-size: 2rem;

}

@media (max-width:700px) {
    .polar-chart {
        width:400px;
    }

    .radar-container {
        width:600px;
    }
}

@media (max-width:500px) {
    .polar-chart {
        width:300px;
    }

    .radar-container {
        width:450px;
    }
}