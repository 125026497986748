:root{
    --dark-pink:#D83F87;
    /* Three primary*/
    --dark-purple:#2A1B3D;
    --purple: #B93DAF;
    --red: #D43725;
    /**/
    --light-purple: #E98074;
    --cloudy-green: #A4B3B6;
}

* {
    margin: 0;
    padding: 0;
}



.container-position{
    width:100%;
    height:100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.position{
    width:200px;
    height:200px;
}