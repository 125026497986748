:root{
    --primary-color:#41B3A3;
    --secondary-color:#E27D60;
    --third-color:#C38D9E;
}

.search-bar-container {
    padding-top:3vh;
    width:40%;
    display:flex;
    flex-direction: column;
    align-items: center;
    min-width: 200px;
  }

.input-wrapper{
    display: flex;
    gap:1rem;
    flex-direction: row;
    background:transparent;
    width:100%;
    height:2.5rem;
    border-radius: 10px;
    border:2px solid white;
    padding: 0 15px;
    align-items: center;
    
}

input {
    background: transparent;
    border-radius: 10px;
    height:100%;
    font-size: 1.25rem;
    width:100%;
    margin-left: 5px;
    border: none;
    color:white;
}

input:focus {
    outline:none;
    color:white;
}

#search-icon {
    height:20px;
    width:auto;
    color:#E27D60;
}

#search-results {
    display:flex;
    flex-direction: column;
    width:100%;
    height:auto;
    max-height: 10rem;
    overflow-y: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    font-size: 1.25rem;
    margin-left: 5px;
    border: none;
    color:white;
    flex-shrink: 0;
}

.results {
    display: flex;
    flex-direction: row;
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    height: fit-content;
}

/* ::-webkit-scrollbar {
    background-color: #2A1B3D;
    border-radius:6px ;
    width:0.3rem;
} */

.linkTo{
    height:fit-content;
    padding:0.75rem;
    
}

.Link{
    text-decoration: none;
    color:white;
}

.Link:hover{
    font-size: 105%;
}

.logo-fyp{
    height:30px;
    padding:3px;
}

