* {
    margin: 0;
    padding: 0;
}



.container-data-page{
    max-height: fit-content;
    overflow-y: hidden;
    overflow-x: hidden;
}


.data-container-page{
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width:100vw;
    max-height: fit-content;
}

